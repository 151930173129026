import { GET_DASHBOARD_DETAILS, GET_DASHBOARD_DETAILS_SUCCESS, GET_DASHBOARD_DETAILS_FAILURE } from '../action/types';
const INIT_STATE = {
    loading: false,
    lists: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DETAILS:
            return { ...state, loading: true };
        case GET_DASHBOARD_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                lists: action.payload,
            };
        case GET_DASHBOARD_DETAILS_FAILURE:
            return { ...state, loading: false };

        default:
            return state;
    }
};
