import {
    GET_CANDIDATE,
    GET_CANDIDATE_SUCCESS,
    GET_CANDIDATE_FAILURE,
    POST_CANDIDATE,
    POST_CANDIDATE_SUCCESS,
    POST_CANDIDATE_FAILURE,
    CANCEL_INTERVIEW,
    CANCEL_INTERVIEW_SUCCESS,
    CANCEL_INTERVIEW_FAILURE,
    POST_BULK_CANDIDATE,
    POST_BULK_CANDIDATE_SUCCESS,
    POST_BULK_CANDIDATE_FAILURE,
    GET_DROPDOWN_QUESTION,
    GET_DROPDOWN_QUESTION_SUCCESS,
    GET_DROPDOWN_QUESTION_FAILURE,
    GET_DROPDOWN_POSITION,
    GET_DROPDOWN_POSITION_SUCCESS,
    GET_DROPDOWN_POSITION_FAILURE,
    RESEND_EMAIL,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_FAILURE,
    COMPARE_JD_CV,
    COMPARE_JD_CV_SUCCESS,
    COMPARE_JD_CV_FAILURE,
    RESUME_INTERVIEW,
    RESUME_INTERVIEW_SUCCESS,
    RESUME_INTERVIEW_FAILURE,
    MANUAL_INVITE,
    MANUAL_INVITE_SUCCESS,
    MANUAL_INVITE_FAILURE,
    FETCH_RESUME_SCORE,
    FETCH_RESUME_SCORE_SUCCESS,
    FETCH_RESUME_SCORE_FAILURE,
    MANUAL_CALL_END,
    MANUAL_CALL_END_SUCCESS,
    MANUAL_CALL_END_FAILURE,
    ADD_CANDIDATE_BANK,
    ADD_CANDIDATE_BANK_SUCCESS,
    ADD_CANDIDATE_BANK_FAILURE,
    GET_CANDIDATE_BANK,
    GET_CANDIDATE_BANK_SUCCESS,
    GET_CANDIDATE_BANK_FAILURE,
    EDIT_CANDIDATE_BANK,
    EDIT_CANDIDATE_BANK_SUCCESS,
    EDIT_CANDIDATE_BANK_FAILURE,
    DELETE_CANDIDATE_BANK,
    DELETE_CANDIDATE_BANK_SUCCESS,
    DELETE_CANDIDATE_BANK_FAILURE,
    GET_CANDIDATE_BANK_LIST,
    GET_CANDIDATE_BANK_LIST_SUCCESS,
    GET_CANDIDATE_BANK_LIST_FAILURE,
    DELETE_CANDIDATE_BANK_LIST,
    DELETE_CANDIDATE_BANK_LIST_SUCCESS,
    DELETE_CANDIDATE_BANK_LIST_FAILURE,
    ADD_EDIT_CANDIDATE_BANK_LIST,
    ADD_EDIT_CANDIDATE_BANK_LIST_SUCCESS,
    ADD_EDIT_CANDIDATE_BANK_LIST_FAILURE,
    GET_CANDIDATE_BANK_DROPDOWN_LIST,
    GET_CANDIDATE_BANK_DROPDOWN_LIST_SUCCESS,
    GET_CANDIDATE_BANK_DROPDOWN_LIST_FAILURE,
    CHECK_CANDIDATE_BANK_EXISTS,
    CHECK_CANDIDATE_BANK_EXISTS_SUCCESS,
    CHECK_CANDIDATE_BANK_EXISTS_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    lists: null,
    questionList: null,
    positionList: null,
    bulkUploadData: null,
    resumeScore: null,
    candidateBank: null,
    candidateBankList: null,
    candidateBankExists: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_RESUME_SCORE:
            return { ...state };
        case FETCH_RESUME_SCORE_SUCCESS:
            return {
                ...state,
                resumeScore: action.payload,
            };
        case FETCH_RESUME_SCORE_FAILURE:
            return { ...state };

        case GET_DROPDOWN_QUESTION:
            return { ...state, loading: true };
        case GET_DROPDOWN_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                questionList: action.payload,
            };
        case GET_DROPDOWN_QUESTION_FAILURE:
            return { ...state, loading: false };

        case GET_DROPDOWN_POSITION:
            return { ...state, loading: true };
        case GET_DROPDOWN_POSITION_SUCCESS:
            return {
                ...state,
                loading: false,
                positionList: action.payload,
            };
        case GET_DROPDOWN_POSITION_FAILURE:
            return { ...state, loading: false };

        case GET_CANDIDATE:
            return {
                ...state,
                loading: true,
            };
        case GET_CANDIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                timeStamp: Math.max(state?.timeStamp, action?.payload?.timeStamp),
                lists: state?.timeStamp > action?.payload?.timeStamp ? state?.lists : action.payload,
            };
        case GET_CANDIDATE_FAILURE:
            const updatedLists = { ...state.lists };
            updatedLists.data = [...state.lists?.data].map((list) => {
                if (!list?.resumeScore || list.resumeScore < 0) list.resumeScore = 0;
                return list;
            });
            return { ...state, loading: false, lists: updatedLists };
        case POST_CANDIDATE:
            return { ...state, loading: true };
        case POST_CANDIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_CANDIDATE_FAILURE:
            return { ...state, loading: false };
        case POST_BULK_CANDIDATE:
            return { ...state, loading: true };
        case POST_BULK_CANDIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkUploadData: action.payload,
            };
        case POST_BULK_CANDIDATE_FAILURE:
            return { ...state, loading: false };
        case CANCEL_INTERVIEW:
            return { ...state, loading: true };
        case CANCEL_INTERVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CANCEL_INTERVIEW_FAILURE:
            return { ...state, loading: false };
        case RESEND_EMAIL:
            return { ...state, loading: true };
        case RESEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case RESEND_EMAIL_FAILURE:
            return { ...state, loading: false };
        case COMPARE_JD_CV:
            return { ...state, compareLoading: true, compareData: '0' };
        case COMPARE_JD_CV_SUCCESS:
            return {
                ...state,
                compareLoading: false,
                compareData: action.payload,
            };

        case COMPARE_JD_CV_FAILURE:
            return { ...state, compareLoading: false, compareData: '0' };
        case RESUME_INTERVIEW:
            return { ...state, loading: true };
        case RESUME_INTERVIEW_SUCCESS:
            return { ...state, loading: false };
        case RESUME_INTERVIEW_FAILURE:
            return { ...state, loading: false };
        case MANUAL_INVITE:
            return { ...state, loading: true };
        case MANUAL_INVITE_SUCCESS:
            return { ...state, loading: false };
        case MANUAL_INVITE_FAILURE:
            return { ...state, loading: false };
        case MANUAL_CALL_END:
            return { ...state, loading: true };
        case MANUAL_CALL_END_SUCCESS:
            return { ...state, loading: false };
        case MANUAL_CALL_END_FAILURE:
            return { ...state, loading: false };
        case ADD_CANDIDATE_BANK:
            return { ...state, loading: true };
        case ADD_CANDIDATE_BANK_SUCCESS:
            return { ...state, loading: false };
        case ADD_CANDIDATE_BANK_FAILURE:
            return { ...state, loading: false };

        case EDIT_CANDIDATE_BANK:
            return { ...state, loading: true };
        case EDIT_CANDIDATE_BANK_SUCCESS:
            return { ...state, loading: false };
        case EDIT_CANDIDATE_BANK_FAILURE:
            return { ...state, loading: false };

        case DELETE_CANDIDATE_BANK:
            return { ...state, loading: true };
        case DELETE_CANDIDATE_BANK_SUCCESS:
            return { ...state, loading: false };
        case DELETE_CANDIDATE_BANK_FAILURE:
            return { ...state, loading: false };

        case GET_CANDIDATE_BANK:
            return { ...state, loading: true };
        case GET_CANDIDATE_BANK_SUCCESS:
            return {
                ...state,
                loading: false,
                timeStamp: Math.max(state?.timeStamp, action?.payload?.timeStamp),
                candidateBank: state?.timeStamp > action?.payload?.timeStamp ? state?.candidateBank : action.payload,
            };
        case GET_CANDIDATE_BANK_FAILURE:
            return { ...state, loading: false };

        case GET_CANDIDATE_BANK_LIST:
            return { ...state, loading: true };
        case GET_CANDIDATE_BANK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                timeStamp: Math.max(state?.timeStamp, action?.payload?.timeStamp),
                candidateBankList:
                    state?.timeStamp > action?.payload?.timeStamp ? state?.candidateBankList : action.payload,
            };
        case GET_CANDIDATE_BANK_LIST_FAILURE:
            return { ...state, loading: false };

        case DELETE_CANDIDATE_BANK_LIST:
            return { ...state, loading: true };
        case DELETE_CANDIDATE_BANK_LIST_SUCCESS:
            return { ...state, loading: false };
        case DELETE_CANDIDATE_BANK_LIST_FAILURE:
            return { ...state, loading: false };

        case ADD_EDIT_CANDIDATE_BANK_LIST:
            return { ...state, loading: true };
        case ADD_EDIT_CANDIDATE_BANK_LIST_SUCCESS:
            return { ...state, loading: false };
        case ADD_EDIT_CANDIDATE_BANK_LIST_FAILURE:
            return { ...state, loading: false };

        case GET_CANDIDATE_BANK_DROPDOWN_LIST:
            return { ...state, loading: true };
        case GET_CANDIDATE_BANK_DROPDOWN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                candidateBankDropdownList: action.payload,
            };
        case GET_CANDIDATE_BANK_DROPDOWN_LIST_FAILURE:
            return { ...state, loading: false };

        case CHECK_CANDIDATE_BANK_EXISTS:
            return { ...state, loading: true };
        case CHECK_CANDIDATE_BANK_EXISTS_SUCCESS:
            return {
                ...state,
                loading: false,
                candidateBankExists: action.payload,
            };
        case CHECK_CANDIDATE_BANK_EXISTS_FAILURE:
            return { ...state, loading: false, candidateBankExists: action.payload };

        default:
            return state;
    }
};
