import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
    Toast,
    localStorageJsonParseDecrypt,
    localStorageEncrypt,
    mainWebsite,
    getAccessToken,
    getIncserveToken,
    clearCookies,
    clearOldCookies,
} from '../../utils/helper';
import {
    LOGIN_USER,
    GET_USER_PERMISSION,
    LOGOUT_USER,
    GET_NOTIFICATION_LIST,
    READ_NOTIFICATION_LIST,
    CLEAR_NOTIFICATION_LIST,
    GET_PRODUCT_LIST,
    FETCH_LOGO,
    GET_SUBSCRIPTION_DATA,
    GET_CONFIGURATIONS,
} from '../action/types';
import {
    loginUserFailure,
    loginUserSuccess,
    getUserPermissionSuccess,
    getUserPermissionFailure,
    logoutUserSuccess,
    logoutUserFailure,
    getNotificationSuccess,
    getNotificationFailure,
    readNotificationSuccess,
    readNotificationFailure,
    clearNotificationSuccess,
    clearNotificationFailure,
    getProductListSuccess,
    getProductListFailure,
    fetchLogoSuccess,
    fetchLogoFailure,
    getSubscriptionDataSuccess,
    getSubscriptionDataFailure,
    getConfigurationsSuccess,
    getConfigurationsFailure,
} from '../action';
import AUTH from '../../utils/api/auth';
import PRODUCT from '../../utils/api/product';
import USERS from '../../utils/api/users';
function* fetchLogoRequest() {
    try {
        const res = yield USERS.get('users/getLogo');
        if (res.status === 200 || res.status === 201) {
            yield put(fetchLogoSuccess(res.data.result));
        }
    } catch (e) {
        yield put(fetchLogoFailure());
    }
}
function* loginUserRequest(action) {
    try {
        const res = yield AUTH.get('auth/oauth/verify-token');
        if (res && res.status === 200) {
            yield put(loginUserSuccess(res.data.result.user));
            if (res.data?.result?.user?.isVerified) {
                if (
                    res.data?.result?.user?.userType === 'employer' ||
                    res.data?.result?.user?.userType === 'subemployer'
                ) {
                    localStorage.clear();
                    clearOldCookies();
                    localStorageEncrypt('userData', JSON.stringify(res.data.result.user));
                    localStorageEncrypt('companyData', JSON.stringify(res.data?.result?.businessDetails));
                    localStorageEncrypt('permissions', JSON.stringify(res.data.result.permissions));
                    yield call(action.payload.callback);
                } else {
                    yield put(loginUserFailure({ data: { message: 'Authorisation Denied' } }));
                }
            } else {
                localStorageEncrypt('userData', JSON.stringify(res.data.result.user));
                yield call(action.payload.callback);
            }
        }
    } catch (e) {
        yield put(loginUserFailure(e.response));
    }
}

function* getUserPermissionRequest() {
    try {
        const res = yield USERS.get(`users/permissions`, {
            headers: {
                Authorization: `Bearer ${getAccessToken}`,
            },
        });
        if (res.status === 200) {
            yield put(getUserPermissionSuccess(res.data.result));
            localStorageEncrypt('permissions', JSON.stringify(res.data.result));
        }
    } catch (e) {
        yield put(getUserPermissionFailure());
    }
}
function* getProductListRequest() {
    try {
        let res;
        if (getIncserveToken) {
            res = yield PRODUCT.post(`products/productList`, { incserveToken: getIncserveToken });
        } else {
            res = yield PRODUCT.get(`products/productList`);
        }
        if (res.status === 200 || res.status === 201) {
            yield put(getProductListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getProductListFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* logoutUserRequest(action) {
    try {
        const res = yield USERS.patch(`auth/logout`);
        if (res.status === 201 || res.status === 200) {
            yield put(logoutUserSuccess());
            yield toast.success(<Toast msg="Logged out successfully." />, {
                autoClose: 5000,
                toastId: 'Logged out successfully.',
            });
            localStorage.clear();
            clearCookies();
            window.location.replace(mainWebsite + '/employer/login');
        }
    } catch (e) {
        yield put(logoutUserFailure());
        yield toast.success(<Toast msg="Logged out successfully." />, {
            autoClose: 5000,
            toastId: 'Logged out successfully.',
        });
        localStorage.clear();
        clearCookies();
        window.location.replace(mainWebsite + '/employer/login');
    }
}
function* getNotificationRequest(action) {
    try {
        const isPageAndPageSize =
            action.payload.page && action.payload.pageSize
                ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
                : '';
        const isSearch = action.payload.search ? `&search=${encodeURIComponent(action.payload.search)}` : '';
        const res = yield USERS.get(`users/notification${isPageAndPageSize}${isSearch}`);
        if (res.status === 200) {
            yield put(getNotificationSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getNotificationFailure());
    }
}

function* readNotificationRequest(action) {
    try {
        const res = yield USERS.put(`users/notification/readAll`);
        if (res.status === 200) {
            yield put(readNotificationSuccess());
            yield call(action.payload.callback());
        }
    } catch (e) {
        yield put(readNotificationFailure());
    }
}

function* clearNotificationRequest(action) {
    try {
        const isNotificationId = action.payload.id ? `?id=${action.payload.id}` : '';
        const res = yield USERS.delete(`users/notification${isNotificationId}`);
        if (res.status === 204) {
            yield put(clearNotificationSuccess());
            yield call(action.payload.callback());
        }
    } catch (e) {
        yield put(clearNotificationFailure());
    }
}

function* getSubscriptionDataRequest() {
    try {
        const res = yield PRODUCT.get(`orders/getIncbotOrder`);
        if (res.status === 200) {
            yield put(getSubscriptionDataSuccess(res?.data?.result));
        }
    } catch (e) {
        yield put(getSubscriptionDataFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

function* getConfigurationsRequest() {
    try {
        const res = yield AUTH.get(`users/incbot/userSettings`);
        if (res.status === 201 || res.status === 200) {
            yield put(getConfigurationsSuccess(res.data?.result));
        }
    } catch (e) {
        yield put(getConfigurationsFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

export function* watchGetSubscriptionDataAPI() {
    yield takeEvery(GET_SUBSCRIPTION_DATA, getSubscriptionDataRequest);
}

export function* watchGetConfigurationsAPI() {
    yield takeEvery(GET_CONFIGURATIONS, getConfigurationsRequest);
}
export function* watchFetchLogoAPI() {
    yield takeEvery(FETCH_LOGO, fetchLogoRequest);
}
export function* watchGetUserPermissionAPI() {
    yield takeEvery(GET_USER_PERMISSION, getUserPermissionRequest);
}
export function* watchGetProductListAPI() {
    yield takeEvery(GET_PRODUCT_LIST, getProductListRequest);
}
export function* watchLogoutUserAPI() {
    yield takeEvery(LOGOUT_USER, logoutUserRequest);
}
export function* watchGetNotificationAPI() {
    yield takeEvery(GET_NOTIFICATION_LIST, getNotificationRequest);
}
export function* watchReadNotificationAPI() {
    yield takeEvery(READ_NOTIFICATION_LIST, readNotificationRequest);
}
export function* watchClearNotificationAPI() {
    yield takeEvery(CLEAR_NOTIFICATION_LIST, clearNotificationRequest);
}
export function* watchLoginUserAPI() {
    yield takeEvery(LOGIN_USER, loginUserRequest);
}
export default function* rootSaga() {
    yield all([
        watchFetchLogoAPI(),
        watchLoginUserAPI(),
        watchGetUserPermissionAPI(),
        watchGetProductListAPI(),
        watchLogoutUserAPI(),
        watchGetNotificationAPI(),
        watchReadNotificationAPI(),
        watchClearNotificationAPI(),
        watchGetSubscriptionDataAPI(),
        watchGetConfigurationsAPI(),
    ]);
}
