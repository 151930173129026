export const PageSize = 10;

export const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const fileExtension = '.xlsx';

export const razorPaySubscriptionURL = 'https://api.razorpay.com/v1/t/subscriptions/';

export const allProducts = {
    incfeed: '62e3bff795a4940eb3c58909',
    incvid: '62bd95c1acd8ae7dd7ad3eb2',
};

export const preQuestions = [
    'Tell me about yourself?',
    'Why are you interested in this position?',
    'Describe your current Job responsibilities?',
];

export const timeSlots = [
    '00:00',
    '00:15',
    '00:30',
    '00:45',
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
    '03:15',
    '03:30',
    '03:45',
    '04:00',
    '04:15',
    '04:30',
    '04:45',
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
    '22:00',
    '22:15',
    '22:30',
    '22:45',
    '23:00',
    '23:15',
    '23:30',
    '23:45',
];

export const displayTimeSlots = {
    '00:00': '12:00 AM',
    '00:15': '12:15 AM',
    '00:30': '12:30 AM',
    '00:45': '12:45 AM',
    '01:00': '01:00 AM',
    '01:15': '01:15 AM',
    '01:30': '01:30 AM',
    '01:45': '01:45 AM',
    '02:00': '02:00 AM',
    '02:15': '02:15 AM',
    '02:30': '02:30 AM',
    '02:45': '02:45 AM',
    '03:00': '03:00 AM',
    '03:15': '03:15 AM',
    '03:30': '03:30 AM',
    '03:45': '03:45 AM',
    '04:00': '04:00 AM',
    '04:15': '04:15 AM',
    '04:30': '04:30 AM',
    '04:45': '04:45 AM',
    '05:00': '05:00 AM',
    '05:15': '05:15 AM',
    '05:30': '05:30 AM',
    '05:45': '05:45 AM',
    '06:00': '06:00 AM',
    '06:15': '06:15 AM',
    '06:30': '06:30 AM',
    '06:45': '06:45 AM',
    '07:00': '07:00 AM',
    '07:15': '07:15 AM',
    '07:30': '07:30 AM',
    '07:45': '07:45 AM',
    '08:00': '08:00 AM',
    '08:15': '08:15 AM',
    '08:30': '08:30 AM',
    '08:45': '08:45 AM',
    '09:00': '09:00 AM',
    '09:15': '09:15 AM',
    '09:30': '09:30 AM',
    '09:45': '09:45 AM',
    '10:00': '10:00 AM',
    '10:15': '10:15 AM',
    '10:30': '10:30 AM',
    '10:45': '10:45 AM',
    '11:00': '11:00 AM',
    '11:15': '11:15 AM',
    '11:30': '11:30 AM',
    '11:45': '11:45 AM',
    '12:00': '12:00 PM',
    '12:15': '12:15 PM',
    '12:30': '12:30 PM',
    '12:45': '12:45 PM',
    '13:00': '01:00 PM',
    '13:15': '01:15 PM',
    '13:30': '01:30 PM',
    '13:45': '01:45 PM',
    '14:00': '02:00 PM',
    '14:15': '02:15 PM',
    '14:30': '02:30 PM',
    '14:45': '02:45 PM',
    '15:00': '03:00 PM',
    '15:15': '03:15 PM',
    '15:30': '03:30 PM',
    '15:45': '03:45 PM',
    '16:00': '04:00 PM',
    '16:15': '04:15 PM',
    '16:30': '04:30 PM',
    '16:45': '04:45 PM',
    '17:00': '05:00 PM',
    '17:15': '05:15 PM',
    '17:30': '05:30 PM',
    '17:45': '05:45 PM',
    '18:00': '06:00 PM',
    '18:15': '06:15 PM',
    '18:30': '06:30 PM',
    '18:45': '06:45 PM',
    '19:00': '07:00 PM',
    '19:15': '07:15 PM',
    '19:30': '07:30 PM',
    '19:45': '07:45 PM',
    '20:00': '08:00 PM',
    '20:15': '08:15 PM',
    '20:30': '08:30 PM',
    '20:45': '08:45 PM',
    '21:00': '09:00 PM',
    '21:15': '09:15 PM',
    '21:30': '09:30 PM',
    '21:45': '09:45 PM',
    '22:00': '10:00 PM',
    '22:15': '10:15 PM',
    '22:30': '10:30 PM',
    '22:45': '10:45 PM',
    '23:00': '11:00 PM',
    '23:15': '11:15 PM',
    '23:30': '11:30 PM',
    '23:45': '11:45 PM',
};

export const expiryDays = [
    { label: '1 day', value: 1, code: 1 },
    { label: '2 days', value: 2, code: 2 },
    { label: '3 days', value: 3, code: 3 },
    { label: '4 days', value: 4, code: 4 },
    { label: '5 days', value: 5, code: 5 },
    { label: '6 days', value: 6, code: 6 },
    { label: '7 days', value: 7, code: 7 },
    { label: '8 days', value: 8, code: 8 },
    { label: '9 days', value: 9, code: 9 },
    { label: '10 days', value: 10, code: 10 },
];

export const expiryType = [
    { label: 'Link Validity', value: 'day-wise' },
    { label: 'Custom Date & Time', value: 'time-wise' },
];

export const customTimeSlots = [
    { value: '', label: 'Select Time Slots' },
    { value: '00:00', label: '12:00 AM' },
    { value: '00:15', label: '12:15 AM' },
    { value: '00:30', label: '12:30 AM' },
    { value: '00:45', label: '12:45 AM' },

    { value: '01:00', label: '01:00 AM' },
    { value: '01:15', label: '01:15 AM' },
    { value: '01:30', label: '01:30 AM' },
    { value: '01:45', label: '01:45 AM' },

    { value: '02:00', label: '02:00 AM' },
    { value: '02:15', label: '02:15 AM' },
    { value: '02:30', label: '02:30 AM' },
    { value: '02:45', label: '02:45 AM' },

    { value: '03:00', label: '03:00 AM' },
    { value: '03:15', label: '03:15 AM' },
    { value: '03:30', label: '03:30 AM' },
    { value: '03:45', label: '03:45 AM' },

    { value: '04:00', label: '04:00 AM' },
    { value: '04:15', label: '04:15 AM' },
    { value: '04:30', label: '04:30 AM' },
    { value: '04:45', label: '04:45 AM' },

    { value: '05:00', label: '05:00 AM' },
    { value: '05:15', label: '05:15 AM' },
    { value: '05:30', label: '05:30 AM' },
    { value: '05:45', label: '05:45 AM' },

    { value: '06:00', label: '06:00 AM' },
    { value: '06:15', label: '06:15 AM' },
    { value: '06:30', label: '06:30 AM' },
    { value: '06:45', label: '06:45 AM' },

    { value: '07:00', label: '07:00 AM' },
    { value: '07:15', label: '07:15 AM' },
    { value: '07:30', label: '07:30 AM' },
    { value: '07:45', label: '07:45 AM' },

    { value: '08:00', label: '08:00 AM' },
    { value: '08:15', label: '08:15 AM' },
    { value: '08:30', label: '08:30 AM' },
    { value: '08:45', label: '08:45 AM' },

    { value: '09:00', label: '09:00 AM' },
    { value: '09:15', label: '09:15 AM' },
    { value: '09:30', label: '09:30 AM' },
    { value: '09:45', label: '09:45 AM' },

    { value: '10:00', label: '10:00 AM' },
    { value: '10:15', label: '10:15 AM' },
    { value: '10:30', label: '10:30 AM' },
    { value: '10:45', label: '10:45 AM' },

    { value: '11:00', label: '11:00 AM' },
    { value: '11:15', label: '11:15 AM' },
    { value: '11:30', label: '11:30 AM' },
    { value: '11:45', label: '11:45 AM' },

    { value: '12:00', label: '12:00 PM' },
    { value: '12:15', label: '12:15 PM' },
    { value: '12:30', label: '12:30 PM' },
    { value: '12:45', label: '12:45 PM' },

    { value: '13:00', label: '01:00 PM' },
    { value: '13:15', label: '01:15 PM' },
    { value: '13:30', label: '01:30 PM' },
    { value: '13:45', label: '01:45 PM' },

    { value: '14:00', label: '02:00 PM' },
    { value: '14:15', label: '02:15 PM' },
    { value: '14:30', label: '02:30 PM' },
    { value: '14:45', label: '02:45 PM' },

    { value: '15:00', label: '03:00 PM' },
    { value: '15:15', label: '03:15 PM' },
    { value: '15:30', label: '03:30 PM' },
    { value: '15:45', label: '03:45 PM' },

    { value: '16:00', label: '04:00 PM' },
    { value: '16:15', label: '04:15 PM' },
    { value: '16:30', label: '04:30 PM' },
    { value: '16:45', label: '04:45 PM' },

    { value: '17:00', label: '05:00 PM' },
    { value: '17:15', label: '05:15 PM' },
    { value: '17:30', label: '05:30 PM' },
    { value: '17:45', label: '05:45 PM' },

    { value: '18:00', label: '06:00 PM' },
    { value: '18:15', label: '06:15 PM' },
    { value: '18:30', label: '06:30 PM' },
    { value: '18:45', label: '06:45 PM' },

    { value: '19:00', label: '07:00 PM' },
    { value: '19:15', label: '07:15 PM' },
    { value: '19:30', label: '07:30 PM' },
    { value: '19:45', label: '07:45 PM' },

    { value: '20:00', label: '08:00 PM' },
    { value: '20:15', label: '08:15 PM' },
    { value: '20:30', label: '08:30 PM' },
    { value: '20:45', label: '08:45 PM' },

    { value: '21:00', label: '09:00 PM' },
    { value: '21:15', label: '09:15 PM' },
    { value: '21:30', label: '09:30 PM' },
    { value: '21:45', label: '09:45 PM' },

    { value: '22:00', label: '10:00 PM' },
    { value: '22:15', label: '10:15 PM' },
    { value: '22:30', label: '10:30 PM' },
    { value: '22:45', label: '10:45 PM' },

    { value: '23:00', label: '11:00 PM' },
    { value: '23:15', label: '11:15 PM' },
    { value: '23:30', label: '11:30 PM' },
    { value: '23:45', label: '11:45 PM' },
];

export const interviewSearchDropdownOptions = {
    gender: [
        { label: 'Select gender', value: '' },
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Other', value: 'Other' },
    ],
    zone: [
        { label: 'Select zone', value: '' },
        { label: 'East', value: 'East' },
        { label: 'West', value: 'West' },
        { label: 'North', value: 'North' },
        { label: 'South', value: 'South' },
    ],
    positionLevel: [
        { label: 'Select position level', value: '' },
        { label: 'Entry Level', value: 'Entry Level' },
        { label: 'Lower Management', value: 'Lower Management' },
        { label: 'Middle Management', value: 'Middle Management' },
        { label: 'Top Management', value: 'Top Management' },
    ],
    age: [
        { label: 'Select age', value: '' },
        { label: '18-24', value: '18-24' },
        { label: '25-31', value: '25-31' },
        { label: '32-38', value: '32-38' },
        { label: '39-45', value: '39-45' },
        { label: '46-51', value: '46-51' },
        { label: '52-58', value: '52-58' },
        { label: '59-65', value: '59-65' },
        { label: '66-72', value: '66-72' },
        { label: '73-79', value: '73-79' },
        { label: '80-85', value: '80-85' },
        { label: '86-92', value: '86-92' },
        { label: '93-99', value: '93-99' },
        { label: '100-108', value: '100-108' },
    ],
    tenure: [
        { label: 'Select tenure', value: '' },
        { label: '0-6', value: '0-6' },
        { label: '7-13', value: '7-13' },
        { label: '14-20', value: '14-20' },
        { label: '21-27', value: '21-27' },
        { label: '28-34', value: '28-34' },
        { label: '35-41', value: '35-41' },
        { label: '42-48', value: '42-48' },
        { label: '49-55', value: '49-55' },
        { label: '56-60', value: '56-60' },
    ],
};
export const secondsTime = [
    { value: 5, label: '05 secs' },
    { value: 10, label: '10 secs' },
    { value: 15, label: '15 secs' },
    { value: 20, label: '20 secs' },
    { value: 25, label: '25 secs' },
    { value: 30, label: '30 secs' },
];
export const minuteTime = [
    { value: 60, label: '1 min' },
    { value: 120, label: '2 mins' },
    { value: 180, label: '3 mins' },
    { value: 240, label: '4 mins' },
    { value: 300, label: '5 mins' },
    { value: 360, label: '6 mins' },
    { value: 420, label: '7 mins' },
    { value: 480, label: '8 mins' },
    { value: 540, label: '9 mins' },
    { value: 600, label: '10 mins' },
];

export const KEY_WORD = {
    GLOBAL: 'global',
    INCBOT: 'incbot',
    EXPORT_TO_EXCEL: 'exportToExcel',
    CREATE: 'create',
    DELETE: 'delete',
    VIEW: 'view',
    POSITIONS: 'positions',
    INTERVIEWS: 'interviews',
    CANDIDATE: 'candidate',
    COMMON_PERMISSION: 'commonPermission',
    SUPPORT: 'support',
    ACCOUNTS_AND_BILLING: 'accountsAndBilling',
    ROLES_AND_SUBUSERS: 'rolesAndSubusers',
    PRODUCT_ID: 'productId',
    LOCALHOST: 'localhost',
    INCRUITER_DOMAIN: 'incruiter.com',
    RESUME_REJECTED: 'resume rejected',
    PENDING: 'pending',
    CANCELED: 'canceled',
    INCFEED: 'incfeed',
    INCVID: 'incvid',
};

export const COOKIES_LIST = ['productId', 'userData', 'moveToIncVid'];

export const COMMON_PAGE_LIST = [
    '/my-profile',
    '/accounts-billing',
    '/settings',
    '/subuser',
    '/contact-enquiries',
    '/roles',
    '/roles-subuser-list',
    '/product',
    '/plan-pricing',
    '/payment-information',
    '/payment-success',
    '/notification',
];

export const TOAST_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
};

export const CUSTOM = 'custom';
export const SUCCESS_MSG = {
    FILE_UPLOAD: 'File uploaded successfully.',
};

export const MAIN_INSTRUCTION_TEXT = `Please follow these instructions carefully to ensure a smooth and successful interview process:\n
* There are {{number}} questions in the interview.
* Please use Chrome browser and laptop for the best interview experience.
* Kindly ensure that you disconnect the VPN before initiating the interview.
* Once the question is skipped, you can't go back. The response can only be recorded once.
* After reading each question, you will be given some time to prepare your response before the recording begins.
* Each question will have a set amount of time to record your response.
* Your response will be automatically submitted when the time limit is reached or you can submit your response and proceed to the next question.
* It's mandatory to switch on audio and video for the interview process.
* Any attempt to manipulate or misuse the interview platform may result in disqualification.
* Please do not refresh the page during the interview.
* The interview shall be recorded throughout the interview for quality and monitoring purposes.
* To start the interview, you have to enter the OTP provided in the Email/WhatsApp.
\nIf you encounter any technical issues during the interview, please contact our technical support team at support@incruiter.com for assistance. Thank you for participating. Your responses will help us assess your skills for the {{positionTitle}} role. Good luck!`;
export const MAX_LIMIT_TEXT = 'Please do not exceed the maximum limit of up to 1700 characters';
