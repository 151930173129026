import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast } from '../../utils/helper';
import {
    GENERATE_ANSWER,
    GENERATE_QUESTION,
    POST_QUESTION,
    CODING_PERMISSION,
    GET_QUESTION_LIBRARY,
} from '../action/types';
import {
    generateQuestionSuccess,
    generateQuestionFailure,
    generateAnswerSuccess,
    generateAnswerFailure,
    createOrUpdateQuestionSuccess,
    createOrUpdateQuestionFailure,
    codingPermissionFailure,
    codingPermissionSuccess,
    getQuestionLibrarySuccess,
    getQuestionLibraryFailure,
} from '../action';
import INTERVIEW from '../../utils/api/interview';
function* postQuestionRequest(action) {
    const { position, label, questions, instructions, totalQuestion, interviewDuration, questionId, type } =
        action?.payload?.finalQuestionForm;
    try {
        let Data = {
            position,
            label,
            questions,
            instruction: instructions,
            totalQuestion,
            totalDuration: interviewDuration,
            type,
        };
        if (questionId) {
            Data.questionId = questionId;
        }
        const res = yield INTERVIEW.post(`incbot/createQuestion`, Data);
        if (res.status === 201) {
            yield put(createOrUpdateQuestionSuccess(res.data.result));
            yield call(action.payload.callback(res.data.result));
        }
    } catch (e) {
        yield put(createOrUpdateQuestionFailure());
    }
}
function* generateQuestionRequest(action) {
    let data = {
        prompt: 'You are a helpful assistant that generates screening questions on the basis of given Job Description. I am giving you a job description required, based on this, generate Screening Questions. You have to act as a HR to generate only 3 Screening Questions. Provide only Questions. No additional information',
        jd: action.payload.jd,
    };
    try {
        const res = yield INTERVIEW.post(`incbot/generateQuestions`, data);
        if (res.status === 201 || res.status === 200) {
            yield put(generateQuestionSuccess(res.data));
            yield call(action.payload.callback(res.data));
        }
    } catch (e) {
        yield put(generateQuestionFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* generateAnswerRequest(action) {
    try {
        const res = yield INTERVIEW.post(`incbot/generateAnswer`, {
            question: action?.payload?.question,
        });
        if (res.status === 201 || res.status === 200) {
            yield put(generateAnswerSuccess(res.data));
            yield call(action.payload.callback(res.data));
        }
    } catch (e) {
        yield put(generateAnswerFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* codingPermissionRequest() {
    try {
        const res = yield INTERVIEW.get(`incbot/checkPermission`);
        if (res.status === 201 || res.status === 200) {
            yield put(codingPermissionSuccess(res.data?.result?.status));
        }
    } catch (e) {
        yield put(codingPermissionFailure());
    }
}
function* getQuestionLibraryRequest() {
    try {
        const res = yield INTERVIEW.get('incbot/questionLibrary');
        if (res.status === 200 || res.status === 201) {
            yield put(getQuestionLibrarySuccess(res.data?.result));
        }
    } catch (e) {
        yield put(getQuestionLibraryFailure());
    }
}
export function* watchGenerateQuestionAPI() {
    yield takeEvery(GENERATE_QUESTION, generateQuestionRequest);
}
export function* watchGenerateAnswerAPI() {
    yield takeEvery(GENERATE_ANSWER, generateAnswerRequest);
}
export function* watchPostQuestionAPI() {
    yield takeEvery(POST_QUESTION, postQuestionRequest);
}
export function* watchCodingPermissionAPI() {
    yield takeEvery(CODING_PERMISSION, codingPermissionRequest);
}
export function* watchGetQuestionLibrary() {
    yield takeEvery(GET_QUESTION_LIBRARY, getQuestionLibraryRequest);
}
export default function* rootSaga() {
    yield all([
        watchGenerateQuestionAPI(),
        watchGenerateAnswerAPI(),
        watchPostQuestionAPI(),
        watchCodingPermissionAPI(),
        watchGetQuestionLibrary(),
    ]);
}
