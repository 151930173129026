import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast, toastMessage } from '../../utils/helper';
import {
    GET_CANDIDATE,
    POST_CANDIDATE,
    CANCEL_INTERVIEW,
    POST_BULK_CANDIDATE,
    GET_DROPDOWN_QUESTION,
    GET_DROPDOWN_POSITION,
    RESEND_EMAIL,
    COMPARE_JD_CV,
    RESUME_INTERVIEW,
    MANUAL_INVITE,
    FETCH_RESUME_SCORE,
    MANUAL_CALL_END,
    ADD_CANDIDATE_BANK,
    GET_CANDIDATE_BANK,
    EDIT_CANDIDATE_BANK,
    DELETE_CANDIDATE_BANK,
    GET_CANDIDATE_BANK_LIST,
    DELETE_CANDIDATE_BANK_LIST,
    ADD_EDIT_CANDIDATE_BANK_LIST,
    GET_CANDIDATE_BANK_DROPDOWN_LIST,
    CHECK_CANDIDATE_BANK_EXISTS,
} from '../action/types';
import {
    getCandidateSuccess,
    getCandidateFailure,
    createOrUpdateCandidateSuccess,
    createOrUpdateCandidateFailure,
    createBulkCandidateSuccess,
    createBulkCandidateFailure,
    cancelInterviewSuccess,
    cancelInterviewFailure,
    getDropdownQuestionSuccess,
    getDropdownQuestionFailure,
    getDropdownPositionSuccess,
    getDropdownPositionFailure,
    resendEmailSuccess,
    resendEmailFailure,
    compareJDCVSuccess,
    compareJDCVFailure,
    resumeInterviewSuccess,
    resumeInterviewFailure,
    manualInviteSuccess,
    manualInviteFailure,
    fetchResumeScoreSuccess,
    fetchResumeScoreFailure,
    manualCallEndSuccess,
    addCandidateBankSuccess,
    addCandidateBankFailure,
    getCandidateBankSuccess,
    getCandidateBankFailure,
    editCandidateBankSuccess,
    editCandidateBankFailure,
    deleteCandidateBankSuccess,
    deleteCandidateBankFailure,
    getCandidateBankListSuccess,
    getCandidateBankListFailure,
    deleteCandidateBankListSuccess,
    deleteCandidateBankListFailure,
    getCandidateBankDropdownListSuccess,
    getCandidateBankDropdownListFailure,
    checkCandidateBankExistsSuccess,
    checkCandidateBankExistsFailure,
} from '../action';
import INTERVIEW from '../../utils/api/interview';
function* fetchResumeScoreRequest(action) {
    try {
        const res = yield INTERVIEW.post('incbot/fetchResumeScore', action?.payload);
        if (res.status === 201 || res.status === 200) {
            yield put(fetchResumeScoreSuccess(res.data.result));
        }
    } catch (e) {
        yield put(fetchResumeScoreFailure());
    }
}
function* getDropdownQuestionRequest(action) {
    try {
        const res = yield INTERVIEW.get(`incbot/getQuestionDropDown/${action?.payload?.position}`);
        if (res.status === 201 || res.status === 200) {
            yield put(getDropdownQuestionSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getDropdownQuestionFailure());
        yield toast.error(<Toast msg={e?.response?.data?.message} />, {
            autoClose: 5000,
            toastId: e?.response?.data?.message,
        });
    }
}
function* getDropdownPositionRequest(action) {
    try {
        const res = yield INTERVIEW.get(`incbot/getPositionDropDown`);
        if (res.status === 201 || res.status === 200) {
            yield put(getDropdownPositionSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getDropdownPositionFailure());
    }
}
function* getCandidateRequest(action) {
    try {
        const status =
            action.payload?.activeTab === 'Network Disconnected'
                ? 'Network Disconnected'
                : action.payload?.activeTab === 'Completed Interviews'
                  ? 'Recommended,Cautiously Recommended,Not Recommended'
                  : '';
        const isSearch = action.payload?.search ? `&search=${encodeURIComponent(action.payload?.search)}` : '';
        const isPageAndPageSize =
            action.payload?.page && action.payload?.pageSize
                ? `?page=${action.payload.page}&pageSize=${action.payload?.pageSize}`
                : '';
        const isSort = action.payload.filter
            ? action.payload.filter.sort
                ? `&sort=${action.payload.filter.sort}`
                : action.payload.sort
                  ? `&sort=${action.payload.sort}`
                  : ''
            : action.payload.sort
              ? `&sort=${action.payload.sort}`
              : '';
        const isFilter = action.payload.filter
            ? (action.payload.filter.from
                  ? action.payload.filter.to
                      ? `&from=${action.payload.filter.from}T00:00:00&to=${action.payload.filter.to}T23:59:59`
                      : `&from=${action.payload.filter.from}T00:00:00&to=3000-12-31T23:59:59`
                  : action.payload.filter.to
                    ? `&from=2000-01-01T00:00:00&to=${action.payload.filter.to}T23:59:59`
                    : '') +
              (action.payload.filter.qFrom && action.payload.filter.qTo
                  ? `&qFrom=${action.payload.filter.qFrom}&qTo=${action.payload.filter.qTo}`
                  : '') +
              (action.payload.filter.status
                  ? `&status=${action.payload.filter.status}`
                  : status
                    ? `&status=${status}`
                    : '') +
              (action.payload.filter.createdBy ? `&createdBy=${action.payload.filter.createdBy}` : '')
            : '';
        const res = yield INTERVIEW.get(`incbot/getCandidates${isPageAndPageSize}${isSearch}${isFilter}${isSort}`);
        if (res.status === 201 || res.status === 200) {
            if (action?.payload?.page === 'all') {
                yield put(getCandidateFailure());
                yield call(action.payload.callback, res.data.result);
            } else {
                yield put(getCandidateSuccess(res.data.result));
            }
        } else {
            yield put(getCandidateFailure());
        }
    } catch (e) {
        yield put(getCandidateFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* postCandidateRequest(action) {
    try {
        const formData = new FormData();
        const { position, question, candidate, expiredAt, startAt, linkExpiryType } = action.payload.form;
        const { linkExpiresIn, resumeScore, checkResumeScore, interviewId, replicateId, resume } = action.payload.form;
        formData.append('position', position);
        formData.append('question', question);
        formData.append('candidate', JSON.stringify(candidate));
        formData.append('expiredAt', expiredAt);
        formData.append('startAt', startAt);
        formData.append('linkExpiryType', linkExpiryType);
        formData.append('linkExpiresIn', linkExpiresIn);
        formData.append('resumeScore', resumeScore || 0);
        formData.append('checkResumeScore', checkResumeScore || false);
        formData.append('cecKey', true);
        if (interviewId) formData.append('interviewId', interviewId);
        if (replicateId) formData.append('replicateId', replicateId);
        if (resume) formData.append('resume', resume);
        const res = yield INTERVIEW.post(`incbot/createAndUpdateCandidate`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201) {
            yield put(createOrUpdateCandidateSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(createOrUpdateCandidateFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* cancelInterviewRequest(action) {
    try {
        const res = yield INTERVIEW.put(`incbot/cancelInterview/${action.payload.interviewId}`, {
            reason: action.payload.reason,
        });
        if (res.status === 201 || res.status === 200) {
            yield put(cancelInterviewSuccess(res.data.result));
            toast.success(<Toast msg={'Interview Canceled Successfully'} />, {
                autoClose: 5000,
                toastId: 'Interview Canceled Successfully',
            });
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(cancelInterviewFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* postBulkCandidateRequest(action) {
    const { position, question, bankId, expiredAt, linkExpiresIn, callback, linkExpiryType, startAt } = action?.payload;
    const url = `incbot/candidateBank/postBulkCandidates`;
    try {
        const res = yield INTERVIEW.post(url, {
            bankId,
            positionId: position,
            questionId: question,
            expiredAt,
            linkExpiresIn,
            linkExpiryType,
            startAt,
        });
        if (res.status === 200 || res.status === 201) {
            yield put(editCandidateBankSuccess());
            yield call(callback);
            toast.success(<Toast msg={`Candidate bank submitted successfully`} />, {
                autoClose: 5000,
                toastId: `Candidate bank submitted successfully`,
            });
        }
    } catch (e) {
        console.log(e);
        yield put(editCandidateBankFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

function* resendEmailRequest(action) {
    try {
        const res = yield INTERVIEW.post(`incbot/reInviteCandidate/${action?.payload?.interviewId}`);
        if (res.status === 201 || res.status === 200) {
            yield put(resendEmailSuccess(res.data.result));
            toast.success(<Toast msg={'Email sent Successfully.'} />, {
                autoClose: 5000,
                toastId: 'Email sent Successfully.',
            });
            yield call(action?.payload?.callback);
        }
    } catch (e) {
        yield put(resendEmailFailure());
    }
}

function* compareJDCVRequest(action) {
    let formData = new FormData();
    const jdcvErrorMsg = 'File format is not correct';
    formData.append('jd', action.payload.jd);
    formData.append('cv', action.payload.cv);
    try {
        const res = yield INTERVIEW.post(`incbot/compareJDToCV`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201 || res.status === 200) {
            yield put(compareJDCVSuccess(res.data));
            const percentage = Number(res.data.matchingPercentage);
            if (percentage <= 0) {
                toast.error(<Toast msg={jdcvErrorMsg} />, {
                    autoClose: 5000,
                    toastId: jdcvErrorMsg,
                });
            }
        }
    } catch (e) {
        yield put(compareJDCVFailure());
        toast.error(<Toast msg={jdcvErrorMsg} />, {
            autoClose: 5000,
            toastId: jdcvErrorMsg,
        });
    }
}

function* postResumeInterviewRequest(action) {
    try {
        const res = yield INTERVIEW.post('incbot/resumeInterview', {
            interviewId: action.payload.resumeId,
        });
        if (res.status === 200 || res.status === 201) {
            yield put(resumeInterviewSuccess());
            toast.success(<Toast msg={'Candidate invited to resume the interview successfully.'} />, {
                autoClose: 5000,
                toastId: 'Candidate invited to resume the interview successfully.',
            });
            yield call(action.payload.callback);
        } else throw new Error('');
    } catch (e) {
        yield put(resumeInterviewFailure());
        toast.error(<Toast msg={'Unable to send an invite to resume the interview. Please contact InCruiter'} />, {
            autoClose: 5000,
            toastId: 'Unable to send an invite to resume the interview. Please contact InCruiter',
        });
    }
}

function* manualInviteRequest(action) {
    try {
        const res = yield INTERVIEW.patch(`incbot/manualInvite/${action.payload.id}`);
        if (res.status === 201) {
            yield put(manualInviteSuccess());
            yield call(action?.payload?.callback);
        }
    } catch (e) {
        yield put(manualInviteFailure());
    }
}

function* manualCallEndRequest(action) {
    try {
        const res = yield INTERVIEW.post(`incbot/callEnd/${action.payload?.roomId}`, {
            endCallStatus: 'Dashboard Call End',
        });
        if (res.status === 200 || res.status === 201) {
            yield put(manualCallEndSuccess());
            yield call(action.payload?.callback);
            toast.success(<Toast msg={'Feedback generation under progress.'} />, {
                autoClose: 5000,
                toastId: 'Feedback generation under progress.',
            });
        } else {
            yield put(manualInviteFailure());
            toast.error(<Toast msg={'Unable to generate feedback report at the moment.'} />, {
                autoClose: 5000,
                toastId: 'Unable to generate feedback report at the moment.',
            });
        }
    } catch {
        yield put(manualInviteFailure());
        toast.error(<Toast msg={'Unable to generate feedback report at the moment.'} />, {
            autoClose: 5000,
            toastId: 'Unable to generate feedback report at the moment.',
        });
    }
}

function* addCandidateBankRequest(action) {
    try {
        const { formData, callback, failureCallback } = action?.payload;
        const res = yield INTERVIEW.post(`incbot/createCandidateBank`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res?.data?.result?.status && res.status === 201) {
            yield put(addCandidateBankSuccess());
            yield call(callback);
            toast.success(<Toast msg={'Candidate bank uploaded successfully.'} />, {
                autoClose: 5000,
                toastId: 'Candidate bank uploaded successfully.',
            });
        } else {
            yield put(addCandidateBankFailure());
            yield call(failureCallback);
            toast.error(<Toast msg={res?.data?.result?.error} />, {
                autoClose: 5000,
                toastId: res?.data?.result?.error,
            });
        }
    } catch (e) {
        console.log(e);
        yield put(addCandidateBankFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

function* getCandidateBankRequest(action) {
    try {
        const isSearch = action.payload?.search ? `&search=${encodeURIComponent(action.payload.search)}` : '';

        const isPageAndPageSize =
            action.payload?.page && action.payload?.pageSize
                ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
                : '';

        const isSort = action.payload?.sort ? `&sort=${action.payload.sort}` : '';

        const res = yield INTERVIEW.get(`incbot/getCandidateBank${isPageAndPageSize}${isSearch}${isSort}`);

        if (res.status === 200 || res.status === 201) {
            yield put(getCandidateBankSuccess(res?.data?.result));
        }
    } catch (e) {
        console.log(e);
        yield put(getCandidateBankFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

export function* editCandidateBankRequest(action) {
    const { name, candidateBankID, callback } = action?.payload;
    try {
        const res = yield INTERVIEW.post(`incbot/updateCandidateBank/${candidateBankID}`, {
            name,
        });
        if (res?.data?.result?.status && res.status === 201) {
            yield put(editCandidateBankSuccess());
            yield call(callback);
            toast.success(<Toast msg={'Candidate bank updated successfully.'} />, {
                autoClose: 5000,
                toastId: 'Candidate bank updated successfully.',
            });
        } else {
            yield put(editCandidateBankFailure());
            toastMessage('error', res?.data?.result?.error);
        }
    } catch (e) {
        console.log(e);
        yield put(editCandidateBankFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

export function* deleteCandidateBankRequest(action) {
    const { candidateBankID, callback } = action?.payload;
    try {
        const res = yield INTERVIEW.delete(`incbot/candidateBank/${candidateBankID}`);
        if (res.status === 204) {
            yield put(deleteCandidateBankSuccess());
            yield call(callback);
            toast.success(<Toast msg={'Candidate bank deleted successfully.'} />, {
                autoClose: 5000,
                toastId: 'Candidate bank deleted successfully.',
            });
        }
    } catch (e) {
        console.log(e);
        yield put(deleteCandidateBankFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

function* getCandidateBankListRequest(action) {
    try {
        const { candidateBankID, search, page, pageSize, sort } = action?.payload;

        const isSearch = search ? `&search=${encodeURIComponent(search)}` : '';

        const isPageAndPageSize = page && pageSize ? `?page=${page}&pageSize=${pageSize}` : '';

        const isSort = sort ? `&sort=${sort}` : '';

        const res = yield INTERVIEW.get(
            `incbot/candidateBankCandidateList/${candidateBankID}${isPageAndPageSize}${isSearch}${isSort}`
        );

        if (res.status === 200 || res.status === 201) {
            yield put(getCandidateBankListSuccess(res?.data?.result));
        }
    } catch (e) {
        console.log(e);
        yield put(getCandidateBankListFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

function* deleteCandidateBankListRequest(action) {
    try {
        const { deleteID, candidateBankID, callback } = action?.payload;

        const res = yield INTERVIEW.delete(`incbot/candidateBankCandidate/${candidateBankID}/${deleteID}`);

        if (res.status === 204) {
            yield put(deleteCandidateBankListSuccess());
            yield call(callback);
            toast.success(<Toast msg={'Candidate deleted successfully.'} />, {
                autoClose: 5000,
                toastId: 'Candidate deleted successfully.',
            });
        }
    } catch (e) {
        console.log(e);
        yield put(deleteCandidateBankListFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

function* addEditCandidateBankListRequest(action) {
    const { candidateBankId, candidateId, name, email, phone, resume, callback } = action?.payload;
    const formData = new FormData();
    if (candidateId) formData.append('candidateId', candidateId);
    if (name) formData.append('name', name);
    if (email) formData.append('email', email);
    if (phone) formData.append('phone', phone.replace('+', ''));
    if (typeof resume === 'object') formData.append('resume', resume);
    const url = `incbot/updateCandidateBankCandidate/${candidateBankId}`;
    try {
        const res = yield INTERVIEW.post(url, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 200 || res.status === 201) {
            yield put(editCandidateBankSuccess());
            yield call(callback);
            toast.success(<Toast msg={`Candidate data ${candidateId ? 'updated' : 'created'} successfully.`} />, {
                autoClose: 5000,
                toastId: `Candidate data ${candidateId ? 'updated' : 'created'} successfully.`,
            });
        }
    } catch (e) {
        console.log(e);
        yield put(editCandidateBankFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

function* getCandidateBankDropdownListRequest() {
    try {
        const res = yield INTERVIEW.get(`incbot/getCandidateBankDropDown`);
        if (res.status === 200 || res.status === 201) {
            yield put(getCandidateBankDropdownListSuccess(res?.data?.result));
        }
    } catch (e) {
        console.log(e);
        yield put(getCandidateBankDropdownListFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

function* checkCandidateBankExistsRequest(action) {
    const { data, callback } = action?.payload;
    try {
        const res = yield INTERVIEW.post(`incbot/candidateBank/checkDuplicate`, {
            bankId: data?.bankId,
            positionId: data?.position,
            questionId: data?.question,
        });
        if (res.status === 200 || res.status === 201) {
            yield put(checkCandidateBankExistsSuccess(res?.data?.result?.status));
            yield call(callback, res?.data?.result?.status);
        }
    } catch (e) {
        console.log(e);
        yield put(checkCandidateBankExistsFailure(false));
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}

export function* watchCheckCandidateBankExistsAPI() {
    yield takeEvery(CHECK_CANDIDATE_BANK_EXISTS, checkCandidateBankExistsRequest);
}

export function* watchGetCandidateBankDropdownListAPI() {
    yield takeEvery(GET_CANDIDATE_BANK_DROPDOWN_LIST, getCandidateBankDropdownListRequest);
}

export function* watchAddEditCandidateBankListAPI() {
    yield takeEvery(ADD_EDIT_CANDIDATE_BANK_LIST, addEditCandidateBankListRequest);
}

export function* watchDeleteCandidateBankListAPI() {
    yield takeEvery(DELETE_CANDIDATE_BANK_LIST, deleteCandidateBankListRequest);
}

export function* watchGetCandidateBankListAPI() {
    yield takeEvery(GET_CANDIDATE_BANK_LIST, getCandidateBankListRequest);
}

export function* watchDeleteCandidateBankAPI() {
    yield takeEvery(DELETE_CANDIDATE_BANK, deleteCandidateBankRequest);
}

export function* watchEditCandidateBankAPI() {
    yield takeEvery(EDIT_CANDIDATE_BANK, editCandidateBankRequest);
}

export function* watchGetCandidateBankAPI() {
    yield takeEvery(GET_CANDIDATE_BANK, getCandidateBankRequest);
}

export function* watchAddCandidateBankAPI() {
    yield takeEvery(ADD_CANDIDATE_BANK, addCandidateBankRequest);
}

export function* watchResendEmailAPI() {
    yield takeEvery(RESEND_EMAIL, resendEmailRequest);
}

export function* watchGetCandidateAPI() {
    yield takeEvery(GET_CANDIDATE, getCandidateRequest);
}
export function* watchPostCandidateAPI() {
    yield takeEvery(POST_CANDIDATE, postCandidateRequest);
}
export function* watchCancelInterviewAPI() {
    yield takeEvery(CANCEL_INTERVIEW, cancelInterviewRequest);
}
export function* watchCompareJDCVAPI() {
    yield takeEvery(COMPARE_JD_CV, compareJDCVRequest);
}
export function* watchPostBulkCandidateAPI() {
    yield takeEvery(POST_BULK_CANDIDATE, postBulkCandidateRequest);
}
export function* watchGetDropdownQuestionAPI() {
    yield takeEvery(GET_DROPDOWN_QUESTION, getDropdownQuestionRequest);
}
export function* watchGetDropdownPositionAPI() {
    yield takeEvery(GET_DROPDOWN_POSITION, getDropdownPositionRequest);
}
export function* watchResumeInterviewAPI() {
    yield takeEvery(RESUME_INTERVIEW, postResumeInterviewRequest);
}
export function* watchManualInviteApi() {
    yield takeEvery(MANUAL_INVITE, manualInviteRequest);
}
export function* watchFetchResumeScoreApi() {
    yield takeEvery(FETCH_RESUME_SCORE, fetchResumeScoreRequest);
}
export function* watchManualCallEnd() {
    yield takeEvery(MANUAL_CALL_END, manualCallEndRequest);
}
export default function* rootSaga() {
    yield all([
        watchCompareJDCVAPI(),
        watchResendEmailAPI(),
        watchGetCandidateAPI(),
        watchPostCandidateAPI(),
        watchCancelInterviewAPI(),
        watchPostBulkCandidateAPI(),
        watchGetDropdownQuestionAPI(),
        watchGetDropdownPositionAPI(),
        watchResumeInterviewAPI(),
        watchManualInviteApi(),
        watchFetchResumeScoreApi(),
        watchManualCallEnd(),
        watchAddCandidateBankAPI(),
        watchGetCandidateBankAPI(),
        watchEditCandidateBankAPI(),
        watchDeleteCandidateBankAPI(),
        watchGetCandidateBankListAPI(),
        watchDeleteCandidateBankListAPI(),
        watchAddEditCandidateBankListAPI(),
        watchGetCandidateBankDropdownListAPI(),
        watchCheckCandidateBankExistsAPI(),
    ]);
}
