import React from 'react';
import { all, takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast } from '../../utils/helper';
import { GET_DASHBOARD_DETAILS } from '../action/types';
import { getDashboardDetailsSuccess, getDashboardDetailsFailure } from '../action';
import INTERVIEW from '../../utils/api/interview';
function* getDashboardDetailsRequest(action) {
    try {
        const { payload } = action;
        const positionInterviewId = payload?.positionInterviewId || '';
        const positionRatingId = payload?.positionRatingId || '';
        const queryParams = `${
            positionInterviewId ? `positionInterviewId=${positionInterviewId}` : ''
        }${positionRatingId ? `&positionRatingId=${positionRatingId}` : ''}`;
        const endpoint = `incbot/dashboardStats?${queryParams}`;
        const res = yield INTERVIEW.get(endpoint);
        if (res.status === 201 || res.status === 200) {
            yield put(getDashboardDetailsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getDashboardDetailsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
export function* watchGetDashboardDetailsAPI() {
    yield takeEvery(GET_DASHBOARD_DETAILS, getDashboardDetailsRequest);
}
export default function* rootSaga() {
    yield all([watchGetDashboardDetailsAPI()]);
}
