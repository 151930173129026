import { combineReducers } from 'redux';
import Candidate from './candidateReducer';
import Position from './positionReducer';
import Question from './questionReducer';
import Dashboard from './dashboardReducer';
import Profile from './profileReducer';
const appReducer = combineReducers({
    Candidate,
    Position,
    Question,
    Dashboard,
    Profile,
});

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;
