import {
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_FAILURE,
    CLEAR_NOTIFICATION_LIST,
    CLEAR_NOTIFICATION_LIST_SUCCESS,
    CLEAR_NOTIFICATION_LIST_FAILURE,
    READ_NOTIFICATION_LIST,
    READ_NOTIFICATION_LIST_FAILURE,
    READ_NOTIFICATION_LIST_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAILURE,
    GET_USER_PERMISSION,
    GET_USER_PERMISSION_SUCCESS,
    GET_USER_PERMISSION_FAILURE,
    FETCH_LOGO,
    FETCH_LOGO_SUCCESS,
    FETCH_LOGO_FAILURE,
    GET_SUBSCRIPTION_DATA,
    GET_SUBSCRIPTION_DATA_SUCCESS,
    GET_SUBSCRIPTION_DATA_FAILURE,
    GET_CONFIGURATIONS,
    GET_CONFIGURATIONS_SUCCESS,
    GET_CONFIGURATIONS_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    notificationList: null,
    productData: null,
    userData: null,
    authErrorMessage: null,
    userPermissionList: null,
    logo: null,
    subscriptionData: null,
    configurations: null,
};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_LOGO:
            return { ...state, loading: false };
        case FETCH_LOGO_SUCCESS:
            return { ...state, loading: false, logo: action.payload };
        case FETCH_LOGO_FAILURE:
            return { ...state, loading: false };
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload,
            };
        case LOGIN_USER_FAILURE:
            return { ...state, loading: false, authErrorMessage: action.payload };
        case GET_NOTIFICATION_LIST:
            return { ...state, loading: true };
        case GET_NOTIFICATION_LIST_SUCCESS:
            return { ...state, loading: false, notificationList: action.payload };
        case GET_NOTIFICATION_LIST_FAILURE:
            return { ...state, loading: false };
        case READ_NOTIFICATION_LIST:
            return { ...state, loading: true };
        case READ_NOTIFICATION_LIST_SUCCESS:
            return { ...state, loading: false };
        case READ_NOTIFICATION_LIST_FAILURE:
            return { ...state, loading: false };
        case CLEAR_NOTIFICATION_LIST:
            return { ...state, loading: true };
        case CLEAR_NOTIFICATION_LIST_SUCCESS:
            return { ...state, loading: false };
        case CLEAR_NOTIFICATION_LIST_FAILURE:
            return { ...state, loading: false };
        case LOGOUT_USER:
            return { ...state, loading: true };
        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case LOGOUT_USER_FAILURE:
            return { ...state, loading: false };
        case GET_PRODUCT_LIST:
            return { ...state, loading: true };
        case GET_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                productData: action.payload,
            };
        case GET_PRODUCT_LIST_FAILURE:
            return { ...state, loading: false };
        case GET_USER_PERMISSION:
            return { ...state };
        case GET_USER_PERMISSION_SUCCESS:
            return { ...state, userPermissionList: action.payload };
        case GET_USER_PERMISSION_FAILURE:
            return { ...state };
        case GET_SUBSCRIPTION_DATA:
            return { ...state };
        case GET_SUBSCRIPTION_DATA_SUCCESS:
            return { ...state, subscriptionData: action.payload };
        case GET_SUBSCRIPTION_DATA_FAILURE:
            return { ...state };
        case GET_CONFIGURATIONS:
            return { ...state, loading: true };
        case GET_CONFIGURATIONS_SUCCESS:
            return { ...state, configurations: action.payload, loading: false };
        case GET_CONFIGURATIONS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
