import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast } from '../../utils/helper';
import {
    GET_POSITION,
    POST_POSITION,
    DELETE_POSITION,
    GET_DOMAIN,
    GET_POSITION_LIST,
    GET_WHITE_LABEL,
    GET_ALL_USERS,
    UPDATE_POSITION_STATUS,
    GENERATE_LINK,
    GET_CANDIDATE_DETAILS_USING_RESUME,
    GET_MANDATORY_SKILL,
    GET_OPTIONAL_SKILL,
    FETCH_SKILLS_TITLE,
    FETCH_SKILLS_JD,
} from '../action/types';
import {
    getPositionSuccess,
    getPositionFailure,
    createOrUpdatePositionSuccess,
    createOrUpdatePositionFailure,
    deletePositionSuccess,
    deletePositionFailure,
    getDomainSuccess,
    getDomainFailure,
    getPositionListSuccess,
    getPositionListFailure,
    getWhiteLabelSuccess,
    getWhiteLabelFailure,
    getAllUsersSuccess,
    getAllUsersFailure,
    updatePositionStatusSuccess,
    updatePositionStatusFailure,
    generateLinkSuccess,
    generateLinkFailure,
    getCandidateDetailsUsingResumeSuccess,
    getCandidateDetailsUsingResumeFailure,
    getMandatorySkillSuccess,
    getMandatorySkillFailure,
    getOptionalSkillSuccess,
    getOptionalSkillFailure,
    fetchSkillsTitleSuccess,
    fetchSkillsTitleFailure,
    fetchSkillsJDSuccess,
    fetchSkillsJDFailure,
} from '../action';
import INTERVIEW from '../../utils/api/interview';
import SKILL from '../../utils/api/skill';
import USERS from '../../utils/api/users';
import { STATUSES } from '../../component/Questions/utils/constants';
function* fetchSkillsTitleRequest(action) {
    try {
        const res = yield INTERVIEW.post(`incbot/gptSkillset`, action.payload.data);
        if (res.status === 201 || res.status === 200) {
            yield put(fetchSkillsTitleSuccess());
            if (res.data.result?.error && res.data.result?.message) {
                yield toast.error(<Toast msg={res.data.result?.message} />, {
                    autoClose: 5000,
                    toastId: res.data.result?.message,
                });
            } else {
                yield call(action.payload.callback(res.data.result));
                yield toast.success(<Toast msg={'Skills fetch successfully.'} />, {
                    autoClose: 5000,
                    toastId: 'Skills fetch successfully.',
                });
            }
        }
    } catch (e) {
        yield put(fetchSkillsTitleFailure());
    }
}
function* fetchSkillsJDRequest(action) {
    try {
        const formData = new FormData();
        formData.append('jd', action.payload.jobDescription);
        const res = yield INTERVIEW.post('incbot/gptjdSkillset', formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201 || res.status === 200) {
            yield put(fetchSkillsJDSuccess());
            if (res.data.result?.error && res.data.result?.message) {
                yield toast.error(<Toast msg={res.data.result?.message} />, {
                    autoClose: 5000,
                    toastId: res.data.result?.message,
                });
            } else {
                yield call(action.payload.callback(res.data.result));
                yield toast.success(<Toast msg={'Skills fetch successfully.'} />, {
                    autoClose: 5000,
                    toastId: 'Skills fetch successfully.',
                });
            }
        }
    } catch (e) {
        yield put(fetchSkillsJDFailure());
    }
}
function* getDomainRequest() {
    try {
        const res = yield SKILL.get('domains');
        if (res.status === 200) {
            yield put(getDomainSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getDomainFailure());
    }
}
function* getWhiteLabelRequest() {
    try {
        const res = yield USERS.get('employers/employerLogo');
        if (res.status === 200) {
            yield put(getWhiteLabelSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getWhiteLabelFailure());
    }
}
function* getPositionListRequest() {
    try {
        const res = yield INTERVIEW.get(`positionlists`);
        if (res.status === 200) {
            yield put(getPositionListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPositionListFailure());
    }
}
function* getPositionRequest(action) {
    try {
        const isSearch = action.payload?.search ? `&search=${encodeURIComponent(action.payload?.search)}` : '';
        const isPageAndPageSize =
            action.payload?.page && action.payload?.pageSize
                ? `?page=${action.payload.page}&pageSize=${action.payload?.pageSize}`
                : '';
        const isSort = action.payload.filter
            ? action.payload.filter.sort
                ? `&sort=${action.payload.filter.sort}`
                : action.payload.sort
                  ? `&sort=${action.payload.sort}`
                  : ''
            : action.payload.sort
              ? `&sort=${action.payload.sort}`
              : '';
        const isFilter = action.payload.filter
            ? (action.payload.filter.from
                  ? action.payload.filter.to
                      ? `&from=${action.payload.filter.from}T00:00:00&to=${action.payload.filter.to}T23:59:59`
                      : `&from=${action.payload.filter.from}T00:00:00&to=3000-12-31T23:59:59`
                  : action.payload.filter.to
                    ? `&from=2000-01-01T00:00:00&to=${action.payload.filter.to}T23:59:59`
                    : '') +
              (action.payload.filter.status ? `&status=${action.payload.filter.status}` : '') +
              (action.payload.filter.createdBy ? `&createdBy=${action.payload.filter.createdBy}` : '')
            : '';
        const res = yield INTERVIEW.get(`incbot/getPositions${isPageAndPageSize}${isSearch}${isFilter}${isSort}`);
        if (res.status === 201 || res.status === 200) {
            if (action?.payload?.page === 'all') {
                yield put(getPositionFailure());
                yield call(action.payload.callback, res.data.result);
            } else {
                yield put(getPositionSuccess(res.data.result));
            }
        }
    } catch (e) {
        yield put(getPositionFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* generateLinkRequest(action) {
    try {
        const res = yield INTERVIEW.post(`incbot/generateLink`, action.payload);
        if (res.status === 201 || res.status === 200) {
            yield put(generateLinkSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(generateLinkFailure());
    }
}
function* postPositionRequest(action) {
    try {
        const formData = new FormData();
        formData.append('title', action.payload.form?.title);
        formData.append('domain', action.payload.form?.domain);
        formData.append(
            'expRange',
            JSON.stringify({
                minYears: action.payload.form?.minExp,
                maxYears: action.payload.form?.maxExp,
            })
        );
        formData.append('skills', JSON.stringify(action?.payload?.skills));
        formData.append('customSkills', JSON.stringify(action?.payload?.customSkills));
        // formData.append("noOfPositions", action.payload.form?.noOfPositions);
        if (action.payload.form?.noOfPositions !== '') {
            formData.append('noOfPositions', action.payload.form?.noOfPositions);
        } else {
            formData.append('noOfPositions', 1);
        }
        if (action.payload.form?.positionId) formData.append('positionId', action.payload.form?.positionId);
        if (action.payload.form?.replicateId) formData.append('replicateId', action.payload.form?.replicateId);
        if (action.payload.form?.jobDescription && action?.payload?.fileChange)
            formData.append('jobDescription', action.payload.form?.jobDescription);

        let message;
        if (action.payload.form?.positionId) {
            message = 'Position updated successfully.';
        } else {
            message = 'Position created successfully.';
        }
        const res = yield INTERVIEW.post(`incbot/createAndUpdatePosition`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201) {
            yield put(createOrUpdatePositionSuccess(res.data.result));
            yield call(action.payload.callback(res.data.result));
            yield toast.success(<Toast msg={`${message}`} />, {
                autoClose: 5000,
                toastId: message,
            });
        }
    } catch (e) {
        yield put(createOrUpdatePositionFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* deletePositionRequest(action) {
    try {
        const res = yield INTERVIEW.delete(`incbot/deletePosition/${action.payload.positionId}`);
        if (res.status === 201 || res.status === 200 || res.status === 204) {
            yield put(deletePositionSuccess(res.data.result));
            toast.success(<Toast msg={'Position Deleted Successfully'} />, {
                autoClose: 5000,
                toastId: 'Position Deleted Successfully',
            });
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(deletePositionFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* getCandidateDetailsUsingResumeRequest(action) {
    let formData = new FormData();
    formData.append('cv', action.payload.resume);
    try {
        const res = yield INTERVIEW.post(`interviews/extractDataFromResume`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201 || res.status === 200) {
            yield put(getCandidateDetailsUsingResumeSuccess(res.data));
            if (res.data.email_id || res.data.phone_number) {
                toast.success(<Toast msg={'Candidate Details Fetched Successfully'} />, {
                    autoClose: 5000,
                    toastId: 'Candidate Details Fetched Successfully',
                });
            }
        }
    } catch (e) {
        yield put(getCandidateDetailsUsingResumeFailure());
        e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* updatePositionStatusRequest(action) {
    try {
        const res = yield INTERVIEW.put(
            `incbot/statusUpdatePosition/${action.payload.positionId}/${action.payload.status}`
        );
        if (res.status === 201 || res.status === 200) {
            yield put(updatePositionStatusSuccess(res.data.result));
            toast.success(
                <Toast
                    msg={`${
                        res.data.result.status === STATUSES.CLOSED
                            ? 'Position Closed Successfully'
                            : 'Position Reopen Successfully'
                    }`}
                />,
                {
                    autoClose: 5000,
                    toastId:
                        res.data.result.status === STATUSES.CLOSED
                            ? 'Position Closed Successfully'
                            : 'Position Reopen Successfully',
                }
            );
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(updatePositionStatusFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                autoClose: 5000,
                toastId: e?.response?.data?.message,
            });
    }
}
function* getAllUsersRequest() {
    try {
        const res = yield USERS.get(`users/dashboard/dropdown`);
        if (res.status === 200) {
            yield put(getAllUsersSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getAllUsersFailure());
    }
}
function* getMandatorySkillRequest() {
    try {
        const res = yield SKILL.get(`skills?type=mandatory`);
        if (res.status === 200) {
            yield put(getMandatorySkillSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getMandatorySkillFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}

function* getOptionalSkillRequest() {
    try {
        const res = yield SKILL.get(`skills?type=optional`);
        if (res.status === 200) {
            yield put(getOptionalSkillSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getOptionalSkillFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}
export function* watchGetPositionAPI() {
    yield takeEvery(GET_POSITION, getPositionRequest);
}
export function* watchPostPositionAPI() {
    yield takeEvery(POST_POSITION, postPositionRequest);
}
export function* watchGenerateLinkAPI() {
    yield takeEvery(GENERATE_LINK, generateLinkRequest);
}
export function* watchDeletePositionAPI() {
    yield takeEvery(DELETE_POSITION, deletePositionRequest);
}
export function* watchGetDomainAPI() {
    yield takeEvery(GET_DOMAIN, getDomainRequest);
}
export function* watchGetCandidateDetailsUsingResumeRequestAPI() {
    yield takeEvery(GET_CANDIDATE_DETAILS_USING_RESUME, getCandidateDetailsUsingResumeRequest);
}
export function* watchGetWhiteLabelAPI() {
    yield takeEvery(GET_WHITE_LABEL, getWhiteLabelRequest);
}
export function* watchGetPositionListAPI() {
    yield takeEvery(GET_POSITION_LIST, getPositionListRequest);
}
export function* watchGetAllUsersAPI() {
    yield takeEvery(GET_ALL_USERS, getAllUsersRequest);
}
export function* watchUpdatePositionStatusAPI() {
    yield takeEvery(UPDATE_POSITION_STATUS, updatePositionStatusRequest);
}
export function* watchGetMandatorySkillAPI() {
    yield takeEvery(GET_MANDATORY_SKILL, getMandatorySkillRequest);
}
export function* watchGetOptionalSkillAPI() {
    yield takeEvery(GET_OPTIONAL_SKILL, getOptionalSkillRequest);
}
export function* watchFetchSkillsTitleAPI() {
    yield takeEvery(FETCH_SKILLS_TITLE, fetchSkillsTitleRequest);
}
export function* watchFetchSkillsJDAPI() {
    yield takeEvery(FETCH_SKILLS_JD, fetchSkillsJDRequest);
}

export default function* rootSaga() {
    yield all([
        watchGenerateLinkAPI(),
        watchGetPositionAPI(),
        watchPostPositionAPI(),
        watchDeletePositionAPI(),
        watchGetDomainAPI(),
        watchGetWhiteLabelAPI(),
        watchGetPositionListAPI(),
        watchGetAllUsersAPI(),
        watchUpdatePositionStatusAPI(),
        watchGetCandidateDetailsUsingResumeRequestAPI(),
        watchGetMandatorySkillAPI(),
        watchGetOptionalSkillAPI(),
        watchFetchSkillsTitleAPI(),
        watchFetchSkillsJDAPI(),
    ]);
}
