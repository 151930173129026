import {
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAILURE,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    GET_USER_PERMISSION,
    GET_USER_PERMISSION_SUCCESS,
    GET_USER_PERMISSION_FAILURE,
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_FAILURE,
    READ_NOTIFICATION_LIST,
    READ_NOTIFICATION_LIST_FAILURE,
    READ_NOTIFICATION_LIST_SUCCESS,
    CLEAR_NOTIFICATION_LIST,
    CLEAR_NOTIFICATION_LIST_SUCCESS,
    CLEAR_NOTIFICATION_LIST_FAILURE,
    FETCH_LOGO,
    FETCH_LOGO_SUCCESS,
    FETCH_LOGO_FAILURE,
    GET_SUBSCRIPTION_DATA,
    GET_SUBSCRIPTION_DATA_SUCCESS,
    GET_SUBSCRIPTION_DATA_FAILURE,
    GET_CONFIGURATIONS,
    GET_CONFIGURATIONS_SUCCESS,
    GET_CONFIGURATIONS_FAILURE,
} from './types';
export const fetchLogo = () => ({
    type: FETCH_LOGO,
});
export const fetchLogoSuccess = (payload) => ({
    type: FETCH_LOGO_SUCCESS,
    payload,
});
export const fetchLogoFailure = () => ({
    type: FETCH_LOGO_FAILURE,
});
export const loginUser = (payload) => ({
    type: LOGIN_USER,
    payload,
});
export const loginUserSuccess = (payload) => ({
    type: LOGIN_USER_SUCCESS,
    payload,
});
export const loginUserFailure = (payload) => ({
    type: LOGIN_USER_FAILURE,
    payload,
});
export const getProductList = () => ({
    type: GET_PRODUCT_LIST,
});
export const getProductListSuccess = (payload) => ({
    type: GET_PRODUCT_LIST_SUCCESS,
    payload,
});
export const getProductListFailure = () => ({
    type: GET_PRODUCT_LIST_FAILURE,
});
export const getUserPermission = () => ({
    type: GET_USER_PERMISSION,
});
export const getUserPermissionSuccess = (payload) => ({
    type: GET_USER_PERMISSION_SUCCESS,
    payload,
});
export const getUserPermissionFailure = () => ({
    type: GET_USER_PERMISSION_FAILURE,
});
export const logoutUser = () => ({
    type: LOGOUT_USER,
});

export const logoutUserSuccess = () => ({
    type: LOGOUT_USER_SUCCESS,
});

export const logoutUserFailure = () => ({
    type: LOGOUT_USER_FAILURE,
});

export const getNotification = (payload) => ({
    type: GET_NOTIFICATION_LIST,
    payload,
});
export const getNotificationSuccess = (payload) => ({
    type: GET_NOTIFICATION_LIST_SUCCESS,
    payload,
});
export const getNotificationFailure = () => ({
    type: GET_NOTIFICATION_LIST_FAILURE,
});
export const readNotification = (payload) => ({
    type: READ_NOTIFICATION_LIST,
    payload,
});
export const readNotificationSuccess = () => ({
    type: READ_NOTIFICATION_LIST_SUCCESS,
});
export const readNotificationFailure = () => ({
    type: READ_NOTIFICATION_LIST_FAILURE,
});
export const clearNotification = (payload) => ({
    type: CLEAR_NOTIFICATION_LIST,
    payload,
});

export const clearNotificationSuccess = (payload) => ({
    type: CLEAR_NOTIFICATION_LIST_SUCCESS,
    payload,
});
export const clearNotificationFailure = () => ({
    type: CLEAR_NOTIFICATION_LIST_FAILURE,
});

export const getSubscriptionData = () => ({
    type: GET_SUBSCRIPTION_DATA,
});
export const getSubscriptionDataSuccess = (payload) => ({
    type: GET_SUBSCRIPTION_DATA_SUCCESS,
    payload,
});
export const getSubscriptionDataFailure = () => ({
    type: GET_SUBSCRIPTION_DATA_FAILURE,
});

export const getConfigurations = () => ({
    type: GET_CONFIGURATIONS,
});

export const getConfigurationsSuccess = (payload) => ({
    type: GET_CONFIGURATIONS_SUCCESS,
    payload,
});

export const getConfigurationsFailure = () => ({
    type: GET_CONFIGURATIONS_FAILURE,
});
