import {
    GET_POSITION,
    GET_POSITION_SUCCESS,
    GET_POSITION_FAILURE,
    POST_POSITION,
    POST_POSITION_SUCCESS,
    POST_POSITION_FAILURE,
    DELETE_POSITION,
    DELETE_POSITION_SUCCESS,
    DELETE_POSITION_FAILURE,
    GET_DOMAIN,
    GET_DOMAIN_SUCCESS,
    GET_DOMAIN_FAILURE,
    GET_POSITION_LIST,
    GET_POSITION_LIST_SUCCESS,
    GET_POSITION_LIST_FAILURE,
    GET_WHITE_LABEL,
    GET_WHITE_LABEL_SUCCESS,
    GET_WHITE_LABEL_FAILURE,
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILURE,
    UPDATE_POSITION_STATUS,
    UPDATE_POSITION_STATUS_SUCCESS,
    UPDATE_POSITION_STATUS_FAILURE,
    GENERATE_LINK,
    GENERATE_LINK_SUCCESS,
    GENERATE_LINK_FAILURE,
    GET_CANDIDATE_DETAILS_USING_RESUME,
    GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS,
    GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE,
    GET_MANDATORY_SKILL,
    GET_MANDATORY_SKILL_SUCCESS,
    GET_MANDATORY_SKILL_FAILURE,
    GET_OPTIONAL_SKILL,
    GET_OPTIONAL_SKILL_SUCCESS,
    GET_OPTIONAL_SKILL_FAILURE,
    FETCH_SKILLS_TITLE,
    FETCH_SKILLS_TITLE_SUCCESS,
    FETCH_SKILLS_TITLE_FAILURE,
    FETCH_SKILLS_JD,
    FETCH_SKILLS_JD_SUCCESS,
    FETCH_SKILLS_JD_FAILURE,
} from '../action/types';
const INIT_STATE = {
    loading: false,
    lists: null,
    domain: null,
    users: null,
    predefinedPositionList: null,
    whiteLabel: null,
    link: null,
    candidateDetails: null,
    mandatorySkill: null,
    optionalSkill: null,
    fetchLoading: false,
    isFetchingPositions: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SKILLS_TITLE:
            return { ...state, fetchLoading: true };
        case FETCH_SKILLS_TITLE_SUCCESS:
            return {
                ...state,
                fetchLoading: false,
            };
        case FETCH_SKILLS_TITLE_FAILURE:
            return { ...state, fetchLoading: false };
        case FETCH_SKILLS_JD:
            return { ...state, fetchLoading: true };
        case FETCH_SKILLS_JD_SUCCESS:
            return {
                ...state,
                fetchLoading: false,
            };
        case FETCH_SKILLS_JD_FAILURE:
            return { ...state, fetchLoading: false };
        case GENERATE_LINK:
            return { ...state, loading: true };
        case GENERATE_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                link: action.payload,
            };
        case GENERATE_LINK_FAILURE:
            return { ...state, loading: false };
        case GET_WHITE_LABEL:
            return { ...state, loading: true };
        case GET_WHITE_LABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                whiteLabel: action.payload,
            };
        case GET_WHITE_LABEL_FAILURE:
            return { ...state, loading: false };
        case UPDATE_POSITION_STATUS:
            return { ...state, loading: true };
        case UPDATE_POSITION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_POSITION_STATUS_FAILURE:
            return { ...state, loading: false };
        case GET_ALL_USERS:
            return { ...state, loading: true };
        case GET_ALL_USERS_SUCCESS:
            return { ...state, loading: false, users: action.payload };
        case GET_ALL_USERS_FAILURE:
            return { ...state, loading: false };
        case GET_DOMAIN:
            return { ...state, loading: true };
        case GET_DOMAIN_SUCCESS:
            return {
                ...state,
                loading: false,
                domain: action.payload,
            };
        case GET_DOMAIN_FAILURE:
            return { ...state, loading: false };
        case GET_POSITION_LIST:
            return { ...state, loading: true };
        case GET_POSITION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                predefinedPositionList: action.payload,
            };
        case GET_POSITION_LIST_FAILURE:
            return { ...state, loading: false };
        case GET_CANDIDATE_DETAILS_USING_RESUME:
            return { ...state, loading: true };
        case GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS:
            return { ...state, loading: false, candidateDetails: action.payload };
        case GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE:
            return { ...state, loading: false };
        case GET_POSITION:
            return {
                ...state,
                isFetchingPositions: true,
                lists: action.payload?.page === 'all' ? state?.lists : null,
            };
        case GET_POSITION_SUCCESS:
            return {
                ...state,
                isFetchingPositions: false,
                timeStamp: Math.max(state?.timeStamp, action?.payload?.timeStamp),
                lists: state?.timeStamp > action?.payload?.timeStamp ? state?.lists : action.payload,
            };
        case GET_POSITION_FAILURE:
            return { ...state, isFetchingPositions: false };
        case POST_POSITION:
            return { ...state, loading: true };
        case POST_POSITION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_POSITION_FAILURE:
            return { ...state, loading: false };
        case DELETE_POSITION:
            return { ...state, loading: true };
        case DELETE_POSITION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_POSITION_FAILURE:
            return { ...state, loading: false };
        case GET_MANDATORY_SKILL:
            return { ...state, loading: true };
        case GET_MANDATORY_SKILL_SUCCESS:
            return {
                ...state,
                loading: false,
                mandatorySkill: action.payload,
            };
        case GET_MANDATORY_SKILL_FAILURE:
            return { ...state, loading: false };

        case GET_OPTIONAL_SKILL:
            return { ...state, loading: true };
        case GET_OPTIONAL_SKILL_SUCCESS:
            return {
                ...state,
                loading: false,
                optionalSkill: action.payload,
            };
        case GET_OPTIONAL_SKILL_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
