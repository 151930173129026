import {
    GET_POSITION,
    GET_POSITION_SUCCESS,
    GET_POSITION_FAILURE,
    POST_POSITION,
    POST_POSITION_SUCCESS,
    POST_POSITION_FAILURE,
    DELETE_POSITION,
    DELETE_POSITION_SUCCESS,
    DELETE_POSITION_FAILURE,
    GET_DOMAIN,
    GET_DOMAIN_SUCCESS,
    GET_DOMAIN_FAILURE,
    GET_POSITION_LIST,
    GET_POSITION_LIST_SUCCESS,
    GET_POSITION_LIST_FAILURE,
    GET_WHITE_LABEL,
    GET_WHITE_LABEL_SUCCESS,
    GET_WHITE_LABEL_FAILURE,
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILURE,
    UPDATE_POSITION_STATUS,
    UPDATE_POSITION_STATUS_SUCCESS,
    UPDATE_POSITION_STATUS_FAILURE,
    GENERATE_LINK,
    GENERATE_LINK_SUCCESS,
    GENERATE_LINK_FAILURE,
    GET_CANDIDATE_DETAILS_USING_RESUME,
    GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS,
    GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE,
    GET_MANDATORY_SKILL,
    GET_MANDATORY_SKILL_SUCCESS,
    GET_MANDATORY_SKILL_FAILURE,
    GET_OPTIONAL_SKILL,
    GET_OPTIONAL_SKILL_SUCCESS,
    GET_OPTIONAL_SKILL_FAILURE,
    FETCH_SKILLS_TITLE,
    FETCH_SKILLS_TITLE_SUCCESS,
    FETCH_SKILLS_TITLE_FAILURE,
    FETCH_SKILLS_JD,
    FETCH_SKILLS_JD_SUCCESS,
    FETCH_SKILLS_JD_FAILURE,
} from './types';
export const fetchSkillsTitle = (payload) => ({
    type: FETCH_SKILLS_TITLE,
    payload,
});
export const fetchSkillsTitleSuccess = () => ({
    type: FETCH_SKILLS_TITLE_SUCCESS,
});
export const fetchSkillsTitleFailure = () => ({
    type: FETCH_SKILLS_TITLE_FAILURE,
});
export const fetchSkillsJD = (payload) => ({
    type: FETCH_SKILLS_JD,
    payload,
});
export const fetchSkillsJDSuccess = () => ({
    type: FETCH_SKILLS_JD_SUCCESS,
});
export const fetchSkillsJDFailure = () => ({
    type: FETCH_SKILLS_JD_FAILURE,
});
export const generateLink = (payload) => ({
    type: GENERATE_LINK,
    payload,
});
export const generateLinkSuccess = (payload) => ({
    type: GENERATE_LINK_SUCCESS,
    payload,
});
export const generateLinkFailure = () => ({
    type: GENERATE_LINK_FAILURE,
});
export const updatePositionStatus = (payload) => ({
    type: UPDATE_POSITION_STATUS,
    payload,
});
export const updatePositionStatusSuccess = (payload) => ({
    type: UPDATE_POSITION_STATUS_SUCCESS,
    payload,
});
export const updatePositionStatusFailure = () => ({
    type: UPDATE_POSITION_STATUS_FAILURE,
});
export const getAllUsers = (payload) => ({
    type: GET_ALL_USERS,
    payload,
});
export const getAllUsersSuccess = (payload) => ({
    type: GET_ALL_USERS_SUCCESS,
    payload,
});
export const getAllUsersFailure = () => ({
    type: GET_ALL_USERS_FAILURE,
});
export const getPositionList = () => ({
    type: GET_POSITION_LIST,
});
export const getPositionListSuccess = (payload) => ({
    type: GET_POSITION_LIST_SUCCESS,
    payload,
});
export const getPositionListFailure = () => ({
    type: GET_POSITION_LIST_FAILURE,
});
export const getDomain = () => ({
    type: GET_DOMAIN,
});
export const getDomainSuccess = (payload) => ({
    type: GET_DOMAIN_SUCCESS,
    payload,
});
export const getDomainFailure = () => ({
    type: GET_DOMAIN_FAILURE,
});
export const getWhiteLabel = () => ({
    type: GET_WHITE_LABEL,
});
export const getWhiteLabelSuccess = (payload) => ({
    type: GET_WHITE_LABEL_SUCCESS,
    payload,
});
export const getWhiteLabelFailure = () => ({
    type: GET_WHITE_LABEL_FAILURE,
});
export const getPosition = (payload) => ({
    type: GET_POSITION,
    payload,
});
export const getPositionSuccess = (payload) => ({
    type: GET_POSITION_SUCCESS,
    payload,
});
export const getPositionFailure = () => ({
    type: GET_POSITION_FAILURE,
});
export const createOrUpdatePosition = (payload) => ({
    type: POST_POSITION,
    payload,
});
export const createOrUpdatePositionSuccess = () => ({
    type: POST_POSITION_SUCCESS,
});
export const createOrUpdatePositionFailure = (payload) => ({
    type: POST_POSITION_FAILURE,
    payload,
});
export const deletePosition = (payload) => ({
    type: DELETE_POSITION,
    payload,
});
export const deletePositionSuccess = () => ({
    type: DELETE_POSITION_SUCCESS,
});
export const deletePositionFailure = () => ({
    type: DELETE_POSITION_FAILURE,
});
export const getCandidateDetailsUsingResume = (payload) => ({
    type: GET_CANDIDATE_DETAILS_USING_RESUME,
    payload,
});
export const getCandidateDetailsUsingResumeSuccess = (payload) => ({
    type: GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS,
    payload,
});
export const getCandidateDetailsUsingResumeFailure = () => ({
    type: GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE,
});
export const getMandatorySkill = () => ({
    type: GET_MANDATORY_SKILL,
});
export const getMandatorySkillSuccess = (payload) => ({
    type: GET_MANDATORY_SKILL_SUCCESS,
    payload,
});
export const getMandatorySkillFailure = () => ({
    type: GET_MANDATORY_SKILL_FAILURE,
});

export const getOptionalSkill = () => ({
    type: GET_OPTIONAL_SKILL,
});
export const getOptionalSkillSuccess = (payload) => ({
    type: GET_OPTIONAL_SKILL_SUCCESS,
    payload,
});
export const getOptionalSkillFailure = () => ({
    type: GET_OPTIONAL_SKILL_FAILURE,
});
