import { GET_DASHBOARD_DETAILS, GET_DASHBOARD_DETAILS_SUCCESS, GET_DASHBOARD_DETAILS_FAILURE } from './types';
export const getDashboardDetails = (payload) => ({
    type: GET_DASHBOARD_DETAILS,
    payload,
});
export const getDashboardDetailsSuccess = (payload) => ({
    type: GET_DASHBOARD_DETAILS_SUCCESS,
    payload,
});
export const getDashboardDetailsFailure = () => ({
    type: GET_DASHBOARD_DETAILS_FAILURE,
});
