import React, { lazy, memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import RoutesFile from './RoutesFile';
const NotFound = lazy(() => import('../container/404NotFound'));
const MainPage = lazy(() => import('../container/MainPage'));
const RouteList = memo(() => {
    return (
        <Routes>
            {RoutesFile?.map((itm, key) => (
                <Route
                    key={key}
                    path={itm?.path}
                    exact={itm?.exact}
                    element={
                        <MainPage
                            name={itm?.name}
                            Component={itm?.component}
                            pvt={itm?.private}
                            resource={itm?.resource}
                            permission={itm?.permission}
                            action={itm?.action}
                            mainResource={itm?.mainResource}
                        />
                    }
                />
            ))}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
});

export default RouteList;
