import { lazy } from 'react';
const CandidateBank = lazy(() => import('../container/Candidates/CandidateBank'));
const CandidateBankList = lazy(() => import('../container/Candidates/CandidateBankList'));
const Dashboard = lazy(() => import('../container/dashboard'));
const Candidates = lazy(() => import('../container/Candidates/Lists'));
const AddCandidates = lazy(() => import('../container/Candidates/Add'));
const Positions = lazy(() => import('../container/Positions/Lists'));
const AddPositions = lazy(() => import('../container/Positions/Add'));
const AddQuestions = lazy(() => import('../container/AddQuestions'));
const RoutesFile = [
    {
        path: '/create-questions',
        exact: true,
        name: 'AddQuestions',
        component: AddQuestions,
        private: true,
        mainResource: 'incBot',
        resource: 'positions',
        action: 'create',
        redirect: false,
    },
    {
        path: '/create-position',
        exact: true,
        name: 'AddPositions',
        component: AddPositions,
        private: true,
        mainResource: 'incBot',
        resource: 'positions',
        action: 'create',
        redirect: false,
    },
    {
        path: '/create-candidate',
        exact: true,
        name: 'AddCandidates',
        component: AddCandidates,
        private: true,
        mainResource: 'incBot',
        resource: 'candidate',
        action: 'create',
        redirect: false,
    },
    {
        path: '/candidate-bank',
        exact: true,
        name: 'CandidateBank',
        component: CandidateBank,
        private: true,
        mainResource: 'incBot',
        resource: 'candidate',
        action: 'view',
        redirect: false,
    },
    {
        path: '/candidate-bank/list',
        exact: true,
        name: 'CandidateBankList',
        component: CandidateBankList,
        private: true,
        mainResource: 'incBot',
        resource: 'candidate',
        action: 'view',
        redirect: false,
    },
    {
        path: '/dashboard',
        exact: true,
        name: 'Dashboard',
        component: Dashboard,
        private: true,
        mainResource: '',
        resource: '',
        action: '',
        redirect: false,
    },
    {
        path: '/candidates',
        exact: true,
        name: 'Candidates',
        component: Candidates,
        private: true,
        mainResource: 'incBot',
        resource: 'candidate',
        action: 'view',
        redirect: false,
    },
    {
        path: '/positions',
        exact: true,
        name: 'Positions',
        component: Positions,
        private: true,
        mainResource: 'incBot',
        resource: 'positions',
        action: 'view',
        redirect: false,
    },
];

export default RoutesFile;
